<template>
    <div>
        <img @click="navigate('/')" alt="Slagerij logo" class="mt-1 pl-1" height="90" width="90" style="position: fixed;z-index:1041;float:left"
             lazy-src="@/assets/images/no-image.webp" src="@/assets/images/mini_logo.webp" />
        <div :style="'height:58px;width:100%;z-index:1040;background-image:url('+require('@/assets/images/menubar.webp')+');position: fixed'" class="hidden-md-and-up">

          <div style="margin-top:.5em;width:100%">
            <div style="margin-left:55%;transform:translate(-50%,0);width:35%;display: flex;justify-content: space-evenly" class="">
              <button aria-label="contact-button" :ripple="false" >
                <v-icon class="customIcon icon-white mail-header" @click="$root.contactForm = true"></v-icon>
              </button>

              <v-btn  aria-label="cart-button" :ripple="false" @click="$root.cart = true;" text>
                <v-badge v-if="$store.getters['shoppingcart/getTotal']"
                    color="red"
                    bottom
                    :content="$store.getters['shoppingcart/getTotal']"
                    :value="$store.getters['shoppingcart/getTotal']"
                    overlap
                >
                  <v-icon class="customIcon icon-white shopping-header"></v-icon>
                </v-badge>
                <v-icon v-if="!$store.getters['shoppingcart/getTotal']" class="customIcon icon-white shopping-header"></v-icon>
              </v-btn>

            </div>
          </div>

            <button style="position: absolute;right:1em;top:.7em" :ripple="false" aria-label="menu-button" id="menu-button" @click="dialog=!dialog">
                <v-icon  v-if="dialog" class="icon-white customIcon close"/>
                <v-icon  v-if="!dialog" class="customIcon icon-white  menu"/>
            </button>
        </div>

        <menu-dialog v-if="dialog" :active="dialog" @close-dialog="dialog=false"/>

    </div>
  </template>

<script>
export default {
    name    : 'MobileMenu',
    components:{
        'MenuDialog': () => import(/* webpackChunkName: "MenuDialog" */ '@/layouts/globals/MenuDialog'),
    },
    data() {
        return {
            selectedItem: null,
            dialog:false,

        };
    },
    methods:{
        navigate(item){
            this.$router.push(item).catch(()=>{});
        }
    }
   };
</script>
