import Home from '../../layouts/Main';

export const routes = [
  {
    path     : '/',
    name     : 'home',
    component: Home,
    meta     : {Auth: false},
  },
  {
    path     : '/beheer/:item/:subItem',
    name     : 'Beheer',
    component: () => import( '../../layouts/ManagementView'),
    meta     : {Auth: true},
  },
  {
    path     : '/beheer/:item/',
    name     : 'Beheer',
    component: () => import( '../../layouts/ManagementView'),
    meta     : {Auth: true},
  },
  {
    path     : '/beheer',
    name     : 'Beheer',
    component: () => import( '../../layouts/ManagementView'),
    meta     : {Auth: true},
  },
  {
    path     : '/openingstijden',
    name     : 'Openingstijden',
    component: () => import( '../../layouts/Openingstijden'),
    meta     : {Auth: false},
  },
  {
    path     : '/bestellen',
    name     : 'Bestellen',
    component: () => import( '../../layouts/Bestellen'),
    meta     : {Auth: false},
  },
  {
    path     : '/reviews',
    name     : 'Reviews',
    component: () => import( '../../layouts/Reviews'),
    meta     : {Auth: false},
  },
  {
    path     : '/assortiment/:tab',
    name     : 'Assortiment',
    component: () => import( '../../layouts/Assortiment'),
    meta     : {Auth: false},
  },
  {
    path     : '/assortiment',
    name     : 'Assortiment',
    component: () => import( '../../layouts/Assortiment'),
    meta     : {Auth: false},
  },
  {
    path     : '/aanbiedingen',
    name     : 'aanbiedingen',
    component: () => import( '../../layouts/Nieuws'),
    meta     : {Auth: false},
  },
  {
    // catch all route
    path     : '/403',
    component: () => import( '../../layouts/error-pages/403'),
    meta     : {Auth: false},
  },
  {
    // catch all route
    path     : '/uitgelogd',
    component: () => import( '../../layouts/error-pages/uitgelogd'),
    meta     : {Auth: false},
  },
  {
    // catch all route
    path     : '/*',
    component: () => import( '../../layouts/error-pages/404'),
    meta     : {Auth: false},
  },
];
