<template>
    <div>
       <v-app :style="isMobile ? 'overflow-x: hidden !important;' : 'overflow: hidden !important;'">

        <kds-snackbar v-if="$root.snackbar"></kds-snackbar>

           <div v-if="!isMobile" style="height:80px">
               <the-header v-if="!isMobile" ref="header" class="header-height" />
           </div>

        <the-mobile-header v-if="isMobile" ref="mobileheader" class="header-height" />
        <contact-form v-if="$root.contactForm" :open="$root.contactForm" ref="contact" />
        <login-form v-if="$root.loginForm" :open="$root.loginForm" ref="login" />
        <cart v-if="$root.cart" :open="$root.cart" ref="cart" />
        <transition name="fade">
            <router-view style="min-height: 90vh" />
        </transition>

        <div v-if="isMobile && $router.currentRoute.name ==='home'" style="position: absolute;color:transparent;top:2500px" v-observe-visibility="footerVisibile">karel de stoute</div>
        <div v-if="!isMobile && $router.currentRoute.name ==='home'" style="position: absolute;color:transparent;top:1200px" v-observe-visibility="footerVisibile">karel de stoute</div>
        <TheFooter v-if="isLoaded || $router.currentRoute.name !=='home'" />
    </v-app>
    </div>
</template>

<script>

import TheMobileHeader from '@/layouts/globals/MobileMenu';

export default {
    name      : 'App',
    components: {
        TheMobileHeader,
        'TheHeader'  : () => import(/* webpackChunkName: "HeaderPC" */ '@/layouts/globals/Header'),
        'TheFooter'  : () => import(/* webpackChunkName: "HeaderMobile" */ '@/layouts/globals/Footer'),
        'ContactForm': () => import(/* webpackChunkName: "Contactform" */ '@/commons/components/ContactForm'),
        'LoginForm': () => import(/* webpackChunkName: "LoginForm" */ '@/commons/components/LoginForm'),
        'Cart': () => import(/* webpackChunkName: "LoginForm" */ '@/commons/components/Cart'),
        'KdsSnackbar': () => import(/* webpackChunkName: "LoginForm" */ '@/commons/components/KdsSnackbar'),
    },
    data() {
        return {
            isActive: false,
            isLoaded: false,
        };
    },
    mounted() {

        if (this.$router.currentRoute.name === 'home' && this.isMobile) {
                    setTimeout(() => {
                        this.loadIcons();
                        this.initGtm('UA-129661748-2');
                    }, 4200);
        } else {
          this.loadIcons();
          this.initGtm('UA-129661748-2');
        }
    },
    methods   : {
        footerVisibile(isVisible, entry) {
            this.trigger = isVisible;
            isVisible ? this.isActive = true : '';
        },
        initGtm(acct) {
            var gaJsHost = (('https:' === document.location.protocol) ? 'https://ssl.' : 'http://www.'),
                pageTracker,
                s;
            s            = document.createElement('script');
            s.src        = gaJsHost + 'google-analytics.com/ga.js';
            s.type       = 'text/javascript';
            s.onloadDone = false;

            function init() {
                pageTracker = _gat._getTracker(acct);
                pageTracker._trackPageview();
            }

            s.onload = function() {
                s.onloadDone = true;
                init();
            };

            if (('loaded' === s.readyState || 'complete' === s.readyState) && !s.onloadDone) {
                s.onloadDone = true;
                init();
            }

            if (window.gtmDidInit) {
                init();
            }

            if (!window.gtmDidInit) {
                window.gtmDidInit = true;
                document.getElementsByTagName('head')[0].appendChild(s);
            }

        },
        loadIcons() {
            this.isLoaded = true;
            var link    = document.createElement('link');
            link.rel    = 'stylesheet';
            link.type   = 'text/css';
            link.href   = 'https://fonts.googleapis.com/css?family=Material+Icons&display=block';
            document.head.appendChild(link);
        },
    },
    computed  : {
        isMobile() {
            return window.innerWidth < 960;
        },
    },
    watch     : {
        $route(to, from) {
          this.loadIcons();
            if (!window.location.href.split('#')[1]) {
                window.scrollTo({
                    top     : 0,
                    behavior: 'smooth',
                });
            }
          setTimeout(()=>{
            this.initGtm('UA-129661748-2');
          },2000);
        },

    },

};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition-property: opacity;
    transition-duration: .75s;
}

.fade-enter-active {
    transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
    opacity: 0
}

div[data-app='true'] {
  background: url('~@/assets/images/yvomulderachtergrond.webp') no-repeat center center fixed !important;
  background-size: 100% 100% !important;
}

</style>
