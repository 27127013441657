/** read .env file */

/** --- Import the needed css files here.. so they are not needed in the header (which is bad for performance) */

require('@/assets/css/custom.css');
require('@/assets/css/main.scss');
//require('default-passive-events');

import Vue from 'vue';

import App from './App.vue';
import router from './router';

import vuetify from './plugins/vuetify';
import store from '@/store/store';
import {ObserveVisibility} from 'vue-observe-visibility';

Vue.directive('observe-visibility', ObserveVisibility);

Vue.config.productionTip = false;

new Vue({
  data  : {
    contactForm : false,
    cart        : false,
    loginForm   : false,
    signOnForm  : false,
    snackbar    : false,
    cacheKey    : 1,
    snackbarText: null,
  },
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app');




