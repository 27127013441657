export default {
  namespaced: true,
  state     : {
    cart: [],
  },

  mutations: {
    ADD_Item(state, id) {
      //check if item exists

      let exists = state.cart.findIndex(item => {
        return item.id === id.id;
      });

      if (exists > -1) {
        state.cart.splice(exists, 1);
        state.cart.push(id);
      }

      if (exists === -1) {
        state.cart.push(id);
      }

    },

    REMOVE_Item(state, index) {
      state.cart.splice(index, 1);
    },
    EMPTY_Cart(state){
      state.cart = [];
    }
  },
  actions  : {
    addItem(context, id) {
      context.commit('ADD_Item', id);
    },

    removeItem(context, index) {
      context.commit('REMOVE_Item', index);
    },
    emptyCart(context, index) {
      context.commit('EMPTY_Cart');
    },
  },
  getters  : {
    getCart : state => state.cart,
    getTotal: state => {
      let total = 0;
      state.cart.forEach(item=>{
        total+=parseInt(item.quantity);
      });
      return total;
    },
  },

};
