import Vue from 'vue';
import Vuex from 'vuex';

import preferences from './modules/preferences';
import shoppingcart from './modules/shoppingcart';

/** persisted state and encryption **/
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

const crypt = (salt, text) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

    if(text.length > 1) {
        return text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("");
    }

    if(text.length === 1) {
        return text;
    }
};

const decrypt = (salt, encoded) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

    if(encoded && encoded.length > 1) {
        return encoded.match(/.{1,2}/g).map((hex) => parseInt(hex, 16)).map(applySaltToChar).map((charCode) => String.fromCharCode(charCode)).join("");
    }

    if(encoded && encoded.length === 1) {
        return encoded;
    }
};

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => decrypt(process.env.VUE_APP_SALT,Cookies.get(key)),
                setItem: (key, value) => Cookies.set(key, crypt(process.env.VUE_APP_SALT,value), { expires: 3, secure: true }),
                removeItem: key => Cookies.remove(key)
            },
        }),
    ],
    modules: {
        preferences,
        shoppingcart
    },

});
