export default {
  namespaced: true,
  state     : {
    user   : [],
    manager: false,
  },

  mutations: {
    changeUser(state, value) {
      state.user = value;
    },
    changeManager(state, value) {
      state.manager = value;
    },
  },
  getters  : {
    getUser   : state => state.user,
    getManager: state => state.manager,
  },

};
